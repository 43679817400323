<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="flex flex-col items-center px-4 py-4">
                <div class="ml-3 flex-shrink-0 w-12 h-12 bg-gray-300 dark:bg-gray-700 rounded-full flex items-center justify-center">
                  <img class="h-10 w-10 " src="/assets/icon/announcement_notification.png" alt="">
                </div>
                <!-- Announcement Content -->
                <div class="ml-4 whitespace-normal min-w-0 overflow-y-auto break-words flex flex-col items-center">
                  <!-- Announcement Title -->
                  <p class="text-lg font-semibold text-gray-900 dark:text-white break-all">{{ announcementText}}</p>
                  <!-- Announcement Description -->
                  <p class="mt-1 text-gray-700 dark:text-gray-300 text-center">{{ descriptionText }}</p>

                  <p class="mt-2 text-gray-700 dark:text-gray-300"><b>Starts on:</b> {{ convertDBDateToDateTime(startDate) }}</p>
                  <p class="mt-1 text-gray-700 dark:text-gray-300" v-if="endDate && endDate.length > 0"><b>Ends on:</b> {{ convertDBDateToDateTime(endDate) }}</p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {
  notificationDetailDialogue,
  useGlobalEventLister,
} from "~/composables/useEventBus";

import {companyStore} from "~/store/companyStore";
import {storeToRefs} from "pinia";
import {userStore} from "~/store/userStore";
import {ApplicationType} from "~/utils/enums/ApplicationType";
import {AnnouncementRecipients} from "~/utils/enums/AnnouncementRecipients";
import {announcementStore} from "~/store/announcementStore";


const useUserStore = userStore()
const useAnnouncementStore = announcementStore();
const {loggedInUser, getUserDetailsByEmailAndCompanyId, setLoggedInUser} = useUserStore;
const { announcementText, descriptionText, startDate, endDate } = defineProps(['announcementText', 'descriptionText', 'startDate', 'endDate']);
let title = ref<string>('');
let description = ref<string>('');
let email = ref<string>('');
let password = ref<string>('');
let phone = ref<string>('');
let currentAnnouncementId = ref<number>();

const useCompanyStore = companyStore()
const {getCompanyId} = storeToRefs(useCompanyStore)
const loadingState = ref<boolean>(false)
const isUpdate = ref<boolean>(false)

const open = ref(false)
let selectedRecipient = ref<number>(AnnouncementRecipients.EVERYONE)

let recipients: { recipientName: string; recipientValue: AnnouncementRecipients; }[];
if (ApplicationType.EVENT) {
  recipients = [
    {recipientName: "Everyone", recipientValue: AnnouncementRecipients.EVERYONE},
  ]
} else if (ApplicationType.PROGRAM) {
  recipients = [
    {recipientName: "Everyone", recipientValue: AnnouncementRecipients.EVERYONE}]
}

function cancel() {
  open.value = false;
  loadingState.value = false
  emptyForm()
}


function setupEventListener() {
  useGlobalEventLister(notificationDetailDialogue(), function (data: any) {
    open.value = true;
  })
}


const emptyForm = () => {
  isUpdate.value = false;
  currentAnnouncementId.value = '';
  title.value = '';
  description.value = '';
}

onMounted(() => {
  setupEventListener();
  emptyForm();
})

</script>

<style scoped>

</style>