<script setup lang="ts">
import {userStore} from "~/store/userStore";

const useUserStore = userStore();
const useClubStore = clubStore();
const useEventStore = eventStore();
const useContestStore = contestStore();
const useGradeStore = gradeStore();
const useCompanyStore = companyStore();
const useSessionStore = sessionStore();
import {useRoute, useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {clubStore} from "~/store/clubStore";
import {gradeStore} from "~/store/gradeStore";
import {companyStore} from "~/store/companyStore";
import {sessionStore} from "~/store/sessionStore";


const route = useRoute();
const router = useRouter();
const {isLoggedInUserAnAdminOfThisCompany,getUserById,getLoggedInUser} = storeToRefs(useUserStore)
const {getPendingPostsOfCurrentEvent} = storeToRefs(useContestStore)
const {getCurrentClub} = storeToRefs(useClubStore)
const {getCompanyId} = storeToRefs(useCompanyStore)
const {postUpdateByAdmin} = useContestStore
import {

  Menu,
  MenuButton,
  MenuItem,
  MenuItems,

} from '@headlessui/vue'
import {
  BellIcon,

} from '@heroicons/vue/24/outline'
import {eventStore} from "~/store/eventStore";
import {contestStore} from "~/store/contestStore";
import type {UpdatePostRb} from "~/models/request/updatePostRb";
import {FeedPostStatus} from "~/utils/enums/FeedPostStatus";
import type {FeedPost} from "~/models/FeedPost";


let showNotification = ref<boolean>(false);


const approveOrRejectPost = async (postId: number, status: number, updatedBy: number, caption: string, mediaUrls: string[], contestId: number,
                                   userId: number, companyId: number, positionId: number | any) => {
  const response = await postUpdateByAdmin(<UpdatePostRb>{
    post_id: postId,
    Status: status,
    UpdatedBy: updatedBy,
    Caption: caption,
    MediaUrls: mediaUrls,
    contest_id: contestId,
    UserId: userId,
    CompanyId: companyId,
  });
}

function acceptAllPendingPost(postList:FeedPost[]){

  for(let post of postList){
    approveOrRejectPost(post.id, FeedPostStatus.ACTIVE,
        getLoggedInUser.value.id,
        post.Caption,
        post.MediaUrls,
        post.contest_id,
        post.UserId,
        post.CompanyId,
        null,)
  }

}
</script>

<template>
  <Menu as="div" class="relative" v-if="isLoggedInUserAnAdminOfThisCompany(getCompanyId)">
    <MenuButton class="-m-1.5 flex items-center ">
      <span class="sr-only">{{ ('View notifications') }}</span>
      <span class="flex relative rounded-full border bg-gray-200 h-8 w-8 justify-center">
           <button type="button" class="text-gray-400 hover:text-gray-500">
                <BellIcon class="h-6 w-6 " aria-hidden="true"/>
            </button>
           <span v-if="getPendingPostsOfCurrentEvent.length!=0" class="absolute right-0 top-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white"/>
       </span>
    </MenuButton>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
          :class="getPendingPostsOfCurrentEvent.length!=0?'overflow-scroll':''"
          class="absolute right-0 z-11 mt-2.5 min-w-[300px] pb-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none max-h-96  bg-white">

        <div v-if="getPendingPostsOfCurrentEvent.length!=0" class="sticky top-0  flex items-end justify-end justify-items-end border-b bg-white">

          <div class="flex-item">
            <button type="button" @click="acceptAllPendingPost(getPendingPostsOfCurrentEvent)"
                    class="inline-flex items-center px-2.5 p-2 text-sm font-semibold text-brand-color bg-transparent shadow-none">
              Accept All ({{getPendingPostsOfCurrentEvent.length}})
            </button>
          </div>
        </div>


        <div v-if="getPendingPostsOfCurrentEvent.length==0" class="h-[100px] w-[400ps] overflow-x-hidden overflow-y-hidden">
<!--          <div class="w-full flex justify-end pt-3 pr-3">-->
<!--            <Icon class="h-4 w-4 text-gray-400" name="akar-icons:cross" />-->
<!--          </div>-->
          <div class="h-full w-full flex flex-col gap-4 items-center justify-center p-3  overflow-x-hidden overflow-y-hidden">
            <div class="bg-gray-100 h-8 w-8 rounded-full flex items-center justify-center">
              <Icon class="h-6 w-6 text-gray-300" name="material-symbols:notifications-rounded" />
            </div>
            <span class=" text-black">No notification found</span>
          </div>
        </div>

        <MenuItem v-slot="{ active }" v-for="post in getPendingPostsOfCurrentEvent" :key="post.id">
          <div class="p-2  border-gray-200 border-b">
            <div class="flex items-start">
              <div class="flex-shrink-0 pt-0.5">
                <img class="h-12 w-12 rounded" :src="`${post.MediaUrls[0]}`" alt=""/>
              </div>
              <div class="ml-3 w-0 flex-1 flex-grow" :set=" user = getUserById(post.UserId)">
                <p class="text-sm font-medium text-gray-900" v-if="user">{{user.FirstName}} {{user.LastName}}</p>
                <p class="mt-1 text-sm text-gray-500">{{ getDateAgo(post.created_at) }}</p>
                <div class="mt-4 flex">
                  <button type="button" @click="approveOrRejectPost(post.id, FeedPostStatus.ACTIVE,
                getLoggedInUser.id,
                post.Caption,
                post.MediaUrls,
                post.contest_id,
                post.UserId,
                post.CompanyId,
                null,)"
                          class="inline-flex items-center rounded-md bg-brand-color px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Accept
                  </button>
                  <button type="button" @click="approveOrRejectPost(post.id, FeedPostStatus.DECLINED,
                getLoggedInUser.id,
                post.Caption,
                post.MediaUrls,
                post.contest_id,
                post.UserId,
                post.CompanyId,
                null,)"
                          class="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Decline
                  </button>
                </div>
              </div>
            </div>

          </div>
        </MenuItem>



      </MenuItems>
    </transition>
  </Menu>
</template>

<style scoped>

</style>

